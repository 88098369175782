<template>
  <div>
    <recent-bookings
      :class="classes"
    />
    <wallet
      :class="classes"
    />
    <balance
      :class="classes"
    />
    <rib
      :client="client"
      :classes="classes"
    />
    <notes
      :class="classes"
    />
  </div>
</template>

<script>
export default {
  components: {
    RecentBookings: () => import('./recent-bookings/Index.vue'),
    Rib: () => import('./rib/Index'),
    Balance: () => import('./balance/Index'),
    Wallet: () => import('./balance/WalletIndex'),
    Notes: () => import('./notes/Index'),
  },
  props: {
    classes: {
      type: String,
      default: 'ml-3'
    },
    client: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style scoped>

</style>
